import React from 'react';
import Script from 'next/script';

/* eslint-disable react/no-danger, max-len */
const FriendbuyAuthScript = () => (
  <>
    <Script
      type='text/javascript'
      src='/api/friend-buy-auth'
      strategy='beforeInteractive'
    />
    <Script
      id='friendbuyAuthScript'
      strategy='afterInteractive'
      dangerouslySetInnerHTML={{
        __html: `
      window["friendbuyAPI"] = friendbuyAPI = window["friendbuyAPI"] || [];

      if (window.friendbuyAuth?.authString?.length > 0 && window.friendbuyAuth?.signature?.length > 0) {
        friendbuyAPI.push([
          "auth",
          window.friendbuyAuth.authString,
          window.friendbuyAuth.signature
        ]);
      }
    `,
      }}
    />
  </>
);

FriendbuyAuthScript.propTypes = {};

FriendbuyAuthScript.defaultProps = {};

export default FriendbuyAuthScript;
